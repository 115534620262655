import { createContext, useContext, useState } from "react";
import { noop, startCase, toNumber } from "lodash";
import { hebrewLang } from "../components/Header";
import { Cookies } from "react-cookie";
import { enTranslations, hebTranslations } from "../translation";

const cookies = new Cookies();
export const langCookieName = "p-design-lang";
const getTranslate = (name, lang) => {
  return lang === hebrewLang ? hebTranslations[name] ?? startCase(name) : enTranslations[name] ?? startCase(name)

}
export const UserContext = createContext({
  isLoading: false,
  setIsLoading: noop,
  loadedCatPages: {},
  setLoadedCatPages: noop,
  isLoggedIn: false,
  setIsLoggedIn: noop,
  lang: hebrewLang,
  setLang: noop,
  translate: {},
});


export function UserProvider({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [loadedCatPages, setLoadedCatPages] = useState({});
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [lang, setLang] = useState(
    cookies.get(langCookieName)
      ? toNumber(cookies.get(langCookieName))
      : hebrewLang
  );
  return (
    <UserContext.Provider
      value={{
        isLoading,
        setIsLoading: (loadingState) => setIsLoading(loadingState),
        loadedCatPages,
        setLoadedCatPages: (contextCat) =>
          setLoadedCatPages({ ...loadedCatPages, ...contextCat }),
        isLoggedIn,
        setIsLoggedIn: (logged) => setIsLoggedIn(logged),
        lang,
        setLang: (language) => {
          setLang(language);
          cookies.set(langCookieName, language);
        },
        translate: (name) => getTranslate(name, lang),
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
