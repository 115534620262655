import React from "react";
import "./index.scss";
import { Tabs, Tab } from "react-bootstrap";
import AddProduct from "./AddProduct";
import ManageProducts from "./ManageProducts";

function Admin() {
  return (
    <div className="adminPage p-4">
      <Tabs defaultActiveKey="add" className="mb-3" fill>
        <Tab eventKey="add" title="Add Product">
          <AddProduct />
        </Tab>
        <Tab eventKey="manage" title="Manage Products">
          <ManageProducts />
        </Tab>
      </Tabs>
    </div>
  );
}

export default Admin;
