import Logo from "../../images/logo.png";

import { Col, Button, Row, Container, Card, Form } from "react-bootstrap";
import "./index.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";

export default function Login() {
  const [error, setError] = useState("");
  const { setIsLoggedIn } = useUser();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    if (
      form.email?.value === "admin@p-design.com" &&
      form.password?.value === "admin@products888"
    ) {
      setIsLoggedIn(true);
      form?.reset();
      setError("");
      navigate("/admin");
    } else {
      setError("Wrong email or password combination");
    }
  };
  return (
    <div className="loginPage">
      <Container>
        <Row className="d-flex justify-content-center align-items-center mt-5">
          <Col md={8} lg={6} xs={12} className="position-relative">
            <div className="border border-3 border-brand position-absolute"></div>
            <Card className="shadow">
              <Card.Body>
                <div className="mb-3 mt-md-4">
                  <div className="login-header mx-auto mb-4">
                    <img src={Logo} alt="logo" className="img-fluid" />
                  </div>
                  <p className="mb-3">Please enter your login and password!</p>
                  <div className="mb-3">
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          Email address
                        </Form.Label>
                        <Form.Control
                          onClick={() => setError("")}
                          required
                          type="email"
                          name="email"
                          placeholder="Enter email"
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="formBasicPassword"
                      >
                        <Form.Label>Password</Form.Label>
                        <Form.Control
                          onClick={() => setError("")}
                          required
                          type="password"
                          name="password"
                          placeholder="Password"
                        />
                        {error ? (
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-block"
                          >
                            {error}
                          </Form.Control.Feedback>
                        ) : null}
                      </Form.Group>
                      <div className="d-grid">
                        <Button
                          variant="primary"
                          className="login-submit"
                          type="submit"
                        >
                          Login
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
