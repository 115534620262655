import React from "react";
import Categories from "./../../components/Categories/index";

function CategoriesPage() {
  return (
    <div className="categoriesPage container p-4 p-md-5">
      <Categories />
    </div>
  );
}

export default CategoriesPage;
