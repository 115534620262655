import React, { useEffect, useRef, useState } from "react";
import "./index.scss";
import { ReactComponent as ContactIcon } from "../../images/contact/contactSvg.svg";
import { GrMapLocation } from "react-icons/gr";
import { GrPhone } from "react-icons/gr";
import { FiMail } from "react-icons/fi";
import { Button, Form } from "react-bootstrap";
import { BsCheck2All } from "react-icons/bs";
import { useUser } from "../../context/UserContext";
import emailjs from "@emailjs/browser";
import Spinner from "react-bootstrap/Spinner";

function ContactPage() {
  const [show, setshow] = useState(false);
  const [loading, setLoading] = useState(false);
  const refe = useRef();

  const { translate } = useUser();
  useEffect(() => emailjs.init("cAFhHnoTUYxs0jCgB"), []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const serviceId = "service_8a0sga2";
    const templateId = "template_kk7fqoc";

    try {
      setLoading(true);
      await emailjs.send(serviceId, templateId, {
        name: event?.target?.name?.value,
        email: event?.target?.email?.value,
        phone: event?.target?.phone?.value,
        message: event?.target?.message?.value,
      });
      refe?.current?.reset();
      setshow(true);
      setTimeout(() => setshow(false), 5000);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contactUsPage p-4">
      <div className="topBanner d-flex flex-column flex-md-row position-relative overflow-hidden mb-5">
        <div className="contactImg"></div>
        <div className="leftSection position-relative">
          <ContactIcon />
          <div className="text h-100 d-flex flex-column justify-content-center">
            <h1>{translate("get_in_touch")}</h1>
            <p className="w-50">{translate("contact_text")}</p>
          </div>
        </div>
      </div>
      <div className="container px-0 px-md-2 d-flex flex-wrap justify-content-around gap-3">
        <a
          href="https://goo.gl/maps/hfo7mmAxZ87dggpcA"
          target={"_blank"}
          rel="noreferrer"
          className="contactCard"
        >
          <GrMapLocation />
          <h2>{translate("address")}</h2>
          Bartaa, basma, zip code: 3002300, Israel
        </a>
        <a href="tel:00972-46050155" className="contactCard">
          <GrPhone />
          <h2>{translate("phone")}</h2>
          00972-46050155
        </a>
        <a href="mailto:info@play-parkdesign.com" className="contactCard">
          <FiMail />
          <h2>{translate("email")}</h2>
          info@play-parkdesign.com
        </a>
      </div>
      <div className="d-flex flex-column-reverse flex-md-row mt-5 gap-4">
        <div className="contactMap w-100 overflow-hidden">
          <iframe
            title="Park design google map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3365.71547557657!2d35.082467099999995!3d32.48030120000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151d05dabb727ca1%3A0xbad6b54c3deaf900!2z16TXkNeo16cg15PXmdeW15nXmdefINee16rXp9eg15kg157XqdeX16fXmdedINeV16bXmdeV15Mg16TXoNeZ150g15HXoiLXng!5e0!3m2!1sen!2s!4v1680101219740!5m2!1sen!2s"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        <div className="contactForm w-100 p-4">
          <Form onSubmit={handleSubmit} ref={refe}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>{translate("name")}</Form.Label>
              <Form.Control
                type="text"
                placeholder={translate("name")}
                required
                name="name"
              />
              <Form.Text className="text-muted">
                {translate("never_share")}
              </Form.Text>
            </Form.Group>

            <div className="d-flex gap-2 mb-3">
              <Form.Group className="w-100" controlId="formBasicPassword">
                <Form.Label>{translate("email")}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={translate("email")}
                  required
                  name="email"
                />
              </Form.Group>
              <Form.Group className="w-100" controlId="formBasicPassword">
                <Form.Label>{translate("phone")}</Form.Label>
                <Form.Control
                  type="phone"
                  placeholder={translate("phone")}
                  required
                  name="phone"
                />
              </Form.Group>
            </div>
            <Form.Group className="mb-4 mb-md-5" controlId="formBasicPassword">
              <Form.Label>{translate("message")}</Form.Label>

              <Form.Control
                as="textarea"
                rows={5}
                placeholder={translate("enter_message")}
                required
                name="message"
              />
            </Form.Group>

            <div className="text-center">
              <Button variant="primary" type="submit">
                {!loading ? (
                  translate("submit")
                ) : (
                  <Spinner animation="border" variant="light" size="sm" />
                )}
              </Button>
            </div>
            {show ? (
              <div className="sent-message mt-2 text-center">
                <BsCheck2All /> {translate("sent_message")}
              </div>
            ) : null}
          </Form>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
