import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Spinner, Toast } from "react-bootstrap";
import "./index.scss";
import { useProducts } from "../../../context/ProductsContext";
import firestore, { storage } from "../../../firebase-setup/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { setDoc, doc } from "firebase/firestore/lite";

function AddProduct() {
  const [images, setImages] = useState([]);
  const [urls, setUrls] = useState([]);
  const [form, setForm] = useState();
  const { categories } = useProducts();
  const [loading, setLoading] = useState();

  const refe = useRef();
  useEffect(() => {
    if (urls.length && urls.length === images.length) {
      const add = async () => {
        const {
          number,
          // name,
          category,
          ageRange,
          numberUsers,
          dropHeight,
          securityArea,
        } = form;
        await setDoc(
          doc(firestore, "Products", number.value?.trim()?.toLowerCase()),
          {
            parent: category.value,
            age_range: ageRange.value?.trim(),
            drop_height: dropHeight.value?.trim(),
            number_users: numberUsers.value?.trim(),
            security_area: securityArea.value?.trim()?.toLowerCase(),
            img: urls?.sort(),
          }
        );
        setUrls([]);
        setImages([]);
      };

      add();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urls]);
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    const form = event.currentTarget;
    setForm(form);
    await upload(form.number.value?.trim()?.toLowerCase());
  };

  const upload = async (path) => {
    const promises = Array.from(images)?.map((file) => {
      const sotrageRef = ref(storage, `products/${path}/${file.name}`);

      const uploadTask = uploadBytesResumable(sotrageRef, file);
      uploadTask.on(
        "state_changed",
        () => {},
        (error) => alert(error),
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) =>
            setUrls((prevState) => [...prevState, downloadURLs])
          );
        }
      );
      return uploadTask;
    });
    await Promise.all(promises).then(() => {
      setLoading(false);
      setTimeout(() => refe.current.reset(), 2000);
    });
  };
  const handleImagesChange = (event) => setImages(event.target.files);
  return (
    <div className="addProductPage mt-5">
      <Form onSubmit={handleSubmit} className="addForm mx-auto" ref={refe}>
        <Form.Group>
          <Form.Label>Product number</Form.Label>
          <Form.Control
            required
            name="number"
            placeholder="Enter product number"
          />
        </Form.Group>

        {/* <Form.Group>
          <Form.Label>Product name</Form.Label>
          <Form.Control name="name" placeholder="Enter product name" />
        </Form.Group> */}

        <Form.Group>
          <Form.Label>Category</Form.Label>
          <Form.Select name="category">
            {categories?.map((cat) => (
              <option key={cat.id} value={cat.name?.toLowerCase()}>
                {cat.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Group>
          <Form.Label>ِAge range</Form.Label>
          <Form.Control name="ageRange" placeholder="Enter age range" />
        </Form.Group>

        <Form.Group>
          <Form.Label>Number of users</Form.Label>
          <Form.Control
            name="numberUsers"
            placeholder="Enter number of users"
          />
        </Form.Group>

        <Form.Group>
          <Form.Label>Drop height (meter)</Form.Label>
          <Form.Control name="dropHeight" placeholder="Enter drop height" />
        </Form.Group>

        <Form.Group>
          <Form.Label>Security area</Form.Label>
          <Form.Control name="securityArea" placeholder="Enter security area" />
        </Form.Group>

        <Form.Group>
          <Form.Label>Images</Form.Label>
          <Form.Control
            name="images"
            type="file"
            multiple
            required
            //accept="image/png , image/jpeg, image/webp"
            onChange={handleImagesChange}
          />
        </Form.Group>
        <div className="text-center mt-4">
          <Button
            variant="primary"
            className="add-product-btn px-5"
            type="submit"
          >
            {loading ? <Spinner animation="border" variant="light" /> : "Add"}
          </Button>
          <Toast
            onClose={() => setLoading(undefined)}
            show={loading === false}
            delay={3000}
            autohide
            className="product-toast fixed-bottom"
            bg="success"
          >
            <Toast.Header>
              <strong className="me-auto">Message</strong>
            </Toast.Header>
            <Toast.Body>Product added successfully!</Toast.Body>
          </Toast>
        </div>
      </Form>
    </div>
  );
}

export default AddProduct;
