import classNames from "classnames";
import React, { useState, useEffect } from "react";
import {
  Nav,
  Navbar,
  NavDropdown,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../images/logo.png";
import "./index.scss";
import { BiMessageDetail } from "react-icons/bi";
import {
  collection,
  getDocs,
  query,
  where,
  documentId,
} from "firebase/firestore/lite";
import firestore from "../../firebase-setup/firebase";
import { snakeCase, debounce } from "lodash";
import { useProducts } from "./../../context/ProductsContext";
import { AiOutlineSearch } from "react-icons/ai";
import hebrowSrc from "../../images/flags/israel.png";
import englishSrc from "../../images/flags/uk.png";
import { useUser } from "../../context/UserContext";

export const hebrewLang = 0;
export const englishLang = 1;

const Header = () => {
  const [sticky, setSticky] = useState(false);
  const [show, setShow] = useState(false);
  const [searchItems, setSearchItems] = useState([]);
  const [search, setSearch] = useState("");
  const { lang, setLang, translate } = useUser();

  const navigate = useNavigate();
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };
  const { textCategories, setCategories } = useProducts();
  const productsRef = collection(firestore, "Products");

  useEffect(() => {
    const load = async () => {
      const ref = collection(firestore, "Categories");
      const result = await getDocs(ref);
      setCategories(result?.docs?.map((x) => ({ id: x.id, ...x.data() })));
    };
    if (firestore) load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firestore]);

  const handleOnSearch = debounce(async (term) => {
    if (term?.length > 2) {
      setSearch(term);
      const prductsQuery = query(
        productsRef,
        where(documentId(), ">=", term.toLowerCase()),
        where(documentId(), "<=", term.toLowerCase() + "\uf8ff")
      );
      const result = await getDocs(prductsQuery);
      setSearchItems(
        result?.docs?.map((x) => ({ id: x.id, ...x.data(), name: x.id }))
      );
    } else {
      setSearch("");
      setSearchItems([]);
    }
  }, 1000);

  const handleOnSelect = (item) => {
    window.location.href = `${window.location.origin}/products/${snakeCase(
      item.parent
    )}/product/${item.id}`;
    // navigate(`products/${snakeCase(item.parent)}/product/${item.id}`);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="xl"
      className={classNames("header justify-content-between", {
        sticky,
      })}
      sticky="top"
      bg="light"
      onScroll={(e) => setSticky(e?.currentTarget?.scrollTop > 8)}
    >
      <Link to="/">
        <Navbar.Brand className="header-logo" as={"span"}>
          <img src={Logo} alt="logo" />
        </Navbar.Brand>
      </Link>

      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse
        className="justify-content-end"
        id="responsive-navbar-nav"
      >
        <Nav className="">
          <Link to="/">
            <Nav.Link href="#" as={"span"}>
              {translate("home_page")}
            </Nav.Link>
          </Link>
          <Link to="/projects">
            <Nav.Link href="#projects" as={"span"}>
              {translate("our_projects")}
            </Nav.Link>
          </Link>
          <NavDropdown
            title={translate("products")}
            show={show}
            onMouseEnter={showDropdown}
            onMouseLeave={hideDropdown}
            id="collasible-nav-dropdown"
            onToggle={(_, event) => {
              if (event?.source === "click") {
                event.originalEvent.preventDefault();
                setShow(false);
                navigate("/products");
              }
            }}
            className="nav-products"
          >
            {textCategories?.map((product) => (
              <Link
                to={`/products/${snakeCase(product)}`}
                key={`nav-${product}`}
              >
                <NavDropdown.Item href={`#${snakeCase(product)}`} as={"span"}>
                  {translate(snakeCase(product))}
                </NavDropdown.Item>
              </Link>
            ))}
          </NavDropdown>
          <Link to="/catalog">
            <Nav.Link href="#catalog" as={"span"}>
              {translate("catalog")}
            </Nav.Link>
          </Link>
          <Link to="/certificates">
            <Nav.Link href="#certificates" as={"span"}>
              {translate("certificates")}
            </Nav.Link>
          </Link>
          <Link to="/about_us">
            <Nav.Link href="#about_us" as={"span"}>
              {translate("about_us")}
            </Nav.Link>
          </Link>
        </Nav>

        <Button
          className="contactUs d-flex position-relative"
          onClick={() => navigate("/contact_us")}
        >
          {translate("contact_us")}
          <div className="contactUs-messageIcon position-absolute">
            <BiMessageDetail />
          </div>
        </Button>
        <div className="searchProducts d-none d-md-block ms-5">
          <InputGroup>
            <InputGroup.Text>
              <AiOutlineSearch />
            </InputGroup.Text>
            <Form.Control
              type="text"
              placeholder={translate("search")}
              onChange={(event) => handleOnSearch(event.target.value)}
            />
          </InputGroup>
          {searchItems?.length ? (
            <div className="search-wrapper">
              {searchItems?.map((item) => (
                <div
                  key={item.id}
                  className="searchItem d-flex p-1"
                  onClick={() => handleOnSelect(item)}
                >
                  <img
                    src={item?.img}
                    alt={`${item.id}-img`}
                    className="img-fluid me-3"
                  />
                  <div className="fw-bold">{item?.id}</div>
                </div>
              ))}
            </div>
          ) : search ? (
            <div className="search-wrapper">No result</div>
          ) : null}
        </div>
        <div className="langSwitch ms-2">
          <Button
            className="bg-transparent border-0 p-0 me-2"
            onClick={() => setLang(hebrewLang)}
          >
            <img
              src={hebrowSrc}
              alt="israel flag"
              className={classNames({
                selected: lang === hebrewLang,
              })}
            />
          </Button>
          <Button
            className="bg-transparent border-0 p-0"
            onClick={() => setLang(englishLang)}
          >
            <img
              src={englishSrc}
              alt="britin flag"
              className={classNames({
                selected: lang === englishLang,
              })}
            />
          </Button>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
