import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../pages/Home";
import Category from "../pages/Category";
import Product from "./../pages/Product";
import CategoriesPage from "./../pages/Categories";
import ContactPage from "./../pages/Contact";
import Catalog from "./../pages/Catalog";
import Login from "./../pages/Login";
import Admin from "../pages/Admin";
import Certificates from "../pages/Certificates";
import { useUser } from "../context/UserContext";
import OurProjects from "../pages/OurProjects";
import AboutUs from "../pages/AboutUs";

function Routers() {
  const { isLoggedIn } = useUser();

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path={"/products/"} element={<CategoriesPage />} />
      <Route path={"/products/:categoryUuid"} element={<Category />} />
      <Route
        path={"/products/:categoryUuid/product/:productUuid"}
        element={<Product />}
      />
      <Route path={"/contact_us"} element={<ContactPage />} />
      <Route path={"/catalog"} element={<Catalog />} />
      <Route path={"/projects"} element={<OurProjects />} />
      <Route path={"/certificates"} element={<Certificates />} />
      <Route path={"/about_us"} element={<AboutUs />} />

      <Route path={"/login"} element={<Login />} />
      <Route path={"/admin"} element={isLoggedIn ? <Admin /> : <Login />} />
      <Route path="*" element={<Home />} />
    </Routes>
  );
}

export default Routers;
