export const hebTranslations = {
  home_page: "עמוד הבית",
  our_projects: "הפרוייקטים שלנו",
  products: "מתקני משחק",
  contact_us: "צרו קשר",
  catalog: "קטלוג להורדה",
  certificates: "תעודות",
  search: "... לחפש", // check with them
  park_projects: "פרוייקטים שלנו",
  park_catalog: "הקטלוג שלנו",
  open_catalog: "לפתוח קטלוג  בקישור חדש",
  park_certificates: "תעודות שלנו",
  open_certificates: "לפתוח תעודות בקישור חדש",
  get_in_touch: "להיות בקשר",
  contact_text: "נשמח לשמוע ממך אפשר להגיע אלינו...",
  address: "כתובת",
  phone: "טלפון",
  email: "אימייל",
  name: "שם",
  never_share: "אנחנו לא נשתף את המייל שלך",
  message: "הודעה",
  enter_message: "לכתוב כאן",
  submit: "שלח", // check with them
  sent_message:
    "Your information is submitted, we will get back to you as soon as possible",
  follow_us: "עקוב אחרינו",
  copy_right: 'כל הזכויות שמורות לפארק דיזיין בע"מ',
  your_partner: "שותפים להקמת גני שעשועים",
  extreme_playground: "מתקני חבלים ואקסטרים",
  parks_playground: "מתקנים משולבים לפארקים",
  kindergarten_playground: "מתקני חצר לגני ילדים",
  dynamics_playground: "מתקני משחק דינמיים",
  swings: "נדנדות",
  springs: "מתקני קפיץ",
  carousels: "קרוסלות",
  fitness: "מתקני כושר לפארקים",
  pro_fitness_equipment: "מתקני כושר מקצועיים סדרת פרו",
  urban_furniture: "ריהוט רחוב",
  shades: "הצללות רשת  וסככות",
  ninja: "מתקני נינג'ה",
  home_content_1:
    'פארק דיזיין בע"מ  מתמחה  בתכנון והקמה של גני שעשועים ופארקי ספורט ומתקני חצר לגני ילדים ומעונות יום , הצללות וריהוט רחוב וגן .  אנו עם הוותק והחדשנות מביאים ללקוחות שלנו מוצרים איכותיים שמיוצרים או מיובאים על ידינו המותאמים לדרישות לקוחותינו .',
  home_content_2:
    "פארק דיזיין חרטה על דגלה את נושא הבטיחות במתקניה ואיכות מוצריה  המיומנות  והמקצועיות  והידע והניסיון  שצברנו מאפשר לנו לתכנן מרחבי משחקה מורכבים  תוך פיתוח מוצרים חדשים וחדישים  ולבצע פרוייקטים מורכבים ולהתמודד בהצלחה עם כל אתגר ",
  about_us: "אודותינו",
};

export const enTranslations = {
  home_page: "Home page",
  our_projects: "Our projects",
  products: "Products",
  contact_us: "Contact us",
  catalog: "Catalog",
  certificates: "Certificates",
  search: "Search ...",
  park_projects: "Park Design Projects",
  park_catalog: "Park Design catalog",
  open_catalog: "Open catalog in a new tab",
  park_certificates: "Park Design Certificates",
  open_certificates: "Open certificates in a new tab",
  get_in_touch: "Get in touch",
  contact_text:
    "Want to get in touch? We'd love to hear from you. Here's how you can reach us...",
  address: "Address",
  phone: "Phone",
  email: "E-mail",
  name: "Name",
  never_share: "We'll never share your email with anyone else.",
  message: "Message",
  enter_message: "Enter Message here",
  submit: "Submit",
  sent_message:
    "Your information is submitted, we will get back to you as soon as possible",
  follow_us: "Follow us",
  copy_right: "Copyright © Park Design. All Rights Reserved",
  your_partner: "Your Partner For Building",
  extreme_playground: "Extreme Playground",
  parks_playground: "Parks Playground",
  kindergarten_playground: "Kindergarten Playground",
  dynamics_playground: "Dynamics Playground",
  swings: "Swings",
  springs: "Springs",
  carousels: "Carousels",
  fitness: "Fitness",
  pro_fitness_equipment: "Pro Fitness Equipment",
  urban_furniture: "Urban Furniture",
  shades: "Shades",
  ninja: "Ninja",
  about_us: "About us",
};
// view equipment trnaslation
