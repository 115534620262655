import Footer from "./components/Footer";
import Header from "./components/Header";
import Routers from "./routes/Routers";
import { useLocation } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { Button } from "react-bootstrap";
import { ImArrowUp } from "react-icons/im";
import { useUser } from "./context/UserContext";
import PageLoader from "./components/PageLoader/index";
import { Helmet } from "react-helmet";
import { mainDescription } from "./meta/site-description";

export function App() {
  const [sticky, setSticky] = useState(false);
  const { isLoading, setIsLoading } = useUser();
  const location = useLocation();
  const ref = useRef();
  const handleScroll = (e) => setSticky(e?.target?.scrollTop > 8);

  const excludedURLs = ["/products/", "/catalog"];
  useEffect(() => {
    if (!excludedURLs.some((url) => location?.pathname?.includes(url))) {
      setIsLoading(true);
      setTimeout(() => setIsLoading(false), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Helmet>
        <title>Park Design</title>
        <meta name="description" content={mainDescription} />
      </Helmet>
      <div
        className={classNames("app vh-100 d-flex flex-column", {
          sticky,
        })}
        onScroll={handleScroll}
        ref={ref}
      >
        <Header />
        <div className="pageContent flex-grow-1">
          <Routers />
        </div>
        <Footer />

        <Button
          className={classNames("scrollTop position-fixed border-0", {
            show: sticky,
          })}
          onClick={() =>
            ref?.current?.scrollTo({
              top: 0,
              behavior: "smooth",
            })
          }
        >
          <ImArrowUp />
        </Button>
        {isLoading ? <PageLoader /> : null}
      </div>
    </>
  );
}
