const ShadesText = () => <div>
<b>מפרט טכני : הצללה מסוג מפרשים /נפתח נסגר /ממבראנה</b><br />
חברת פארק דיזיין בע"מ משתמשת באריג מחוזק של חברת שהד ווונדרפלסט
הנודעת בזכות אריגה יצירתית.האריג אינו סופג לחות ועמיד לאורך שנים, הן בצבעו והן בחוזקו.
חומר גלם: עשוי פוליאתילן בעל דחיסות גבוהה עם מסנני .V.U
<br />
נתונים טכניים לאריג:
<br />
חומר גלם:
עשוי פוליאתילן בעל דחיסות גבוהה ( HDPE ( לא ממוחזר, המעניק לאריג חוזק ויציבות לאורך
שנים. האריג בעל אלסטיות ויציבות, גם לאחר מתיחה או רוח יחזור למצבו הקודם.<br />
מבנה האריג: אריגה ייחודית במונופילמנט – צורת אריגה זו מונעת תהליך פרימת האריג גם במקרה של
קרע.<br />
חוזק קריעה: לפי תקן 2261/96 ASTMD שתי 022-N , ערב -198. N
ביקוע: 3.7 KN קילו ניוטון (לפי תקן ASTM 7373 או תקן ישראלי מקביל).<br />
משקל החומר: מינימום 702 גרם למ"ר.<br />
אורך חיים:<br />
לאריג עם תקן אש- מינימום חשוף לשמש במשך 3 שנים מפני התבלות מקרינת .V.U<br />
לאריג ללא תקן אש- מינימום חשוף לשמש במשך 02 שנים מפני התבלות מקרינת V.U<br />
מינימום טמפרטורה: מינוס 02 מעלות צלזיוס.<br />
מקסימום טמפרטורה: 72 מעלות צלזיוס.<br />
עובי: מינימום 0.7 מילימטר<br />
הצללה: מינימום 02% – 07% )לפי תקן ישראלי 347 (.
מסנני V.U : מינימום 00% – 07% )לפי תקן ישראלי 347 (.
אחוז מונופילמנט: מינימום 22% .<br />

נתונים טכניים נוספים:<br />
מפרט אביזרים וכבלים:<br />
כבלים: 6 או 7 מ"מ מגולוונים לפי תקן ישראלי 262 .<br />
מפרט מתכת:<br />
מתכת: עמודים מגולוונים .<br />
עמודים4: " " -6 " -7 (צול)- מתכת ו לפי מפרט קונסטרוקטור.<br />
•עמודים מגולוונים.<br />
•אביזרי מתכת מגולוונת.<br />
•כבלים מגולוונים לפי ת"י 262 .<br />

.
מפרט צביעה:<br />
צביעת העמודים מתבצעת בצבע פוליאורטיני דו רכיבי רטוב, מאושר ע"י מכון התקנים לשימוש במוסדות
חינוך סוג צבע : אוניקריל ללא עופרת של נירלט.<br />
מפרט תפירה:<br />
התפירה: תהיה במכונת דו מחט עם חוט מונופילמנט, כאשר חוט התפירה עשוי מאותו החומר .


</div>;

export default ShadesText;
