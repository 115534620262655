export const mainDescription = `מתקני משחקים משולבים ממתכת של פארק דיזיין בע"מ
מפרט חומרים:
המתקנים מיוצרים מצינורות 4 צול מגולוון או 3" מגולוון ,
הצבוע בצביעה אלקטרוסטטית ( צבע תנור ) .
במתקנים משולבים חבקים יחודיים מאלומיניום ופלסטיק בשיטת הזרקה קשיחים ועמידים וברגי נירוסטה ,
לחיזוק מושלם של המתקנים והאלמנטים.
המתקנים משלבים אלמנטים נוספים להנאת הילדים המשחקים במתקנים:
אלמנטים ממתכת - גשרים, סולם אופקי, סולמות טיפוס וקירות טיפוס אינדיאני ממתכת או פלסטיק .
אלמטים מפלסטיק - מגלשות, מנהרות זחילה ובועה שקופה..
עמידות המתקנים:
מלבד עמידות המתקנים מפני הוונדליזם,
החלקים ממתכת עמידים בפני רטיבות, חום ואש
לאורך שנים ובעלות תחזוקה נמוכה.
אפשרויות הצבה למתקנים:
גני שעשועים, פארקים ציבוריים, מגרשי משחקים וגינות ציבוריות וחצרות גני ילדים ובתי ספר . .

מתקני משחקים משולבים מעץ :
מתקני משחק מעץ מיוצרים מעץ אורן  פיני סוג 5.
העץ עובר תהליך חיטוי ושימור (אימפרגנציה) נגד רטיבות, מזיקים ואש.
המתקנים משלבים אלמנטים נוספים ליצירת חוויה עבור הילדים:
אלמנטים ממתכת - צינור גלישה ומנהרות זחילה.
אלמנטים מפלסטיק - מגלשות,גגונים ומנהרות זחילה..
אלמנטים מכבלים וחבלים - קיר טיפוס חבל, רשת חבלים ולוחות פוליתילין דחוס hdpe   .
פעילות אתגרית לילדים:
טיפוס בסולמות, חבלים, מדרגות, גלישה במגלשות, מנהרות וצינורות.
המתקנים מתאימים לילדים בכל הגילאים בהשגחת מבוגר.
במידה והמתקן מוגבל לגילאים מסויימים, יהיה מצויין על גבי המתקן.
המתקנים בטיחותיים ומיוצרים בהתאם לדרישות מכון התקנים הישראלי.

מתקני משחק מעץ מתאימים לגני שעשועים, מגרשי משחקים, גנים ציבוריים, גני ילדים ..
מתקני משחק מעץ ניתן להתקין על משטח בולם למתקני משחקים מהסוגים הבאים:
סומסומית גן תקנית  למתקני משחק
חול ים למתקני משחק
משטח גומי למתקני משחק
דשא סינטטי משולב גומי למתקני משחק.

מתקני נדנדות :

מתקן נדנדות "חובה" בכל גן שעשועים ציבורי.
בקטגוריית הנדנדות תוכלו למצוא:
נדנדות לפעוטות / נדנדות תינוקות עם מושב נדנדה מיוחד לגיל הרך.
נדנדות לילדים / נדנדות לבוגרים עם מושב נדנדה שטוח מגומי.
נדנדות מיוחדות עם מושב נדנדה מרשת חבלים.

מאושרות על ידי מכון התקנים הישראלי תקן 1498.
הנדנדות מתאימות לגני ילדים, פארקים, גנים ציבוריים, בתי ספר ועוד.
הנדנדות מתאימות ועמידות לתנאי חוץ
חובה בכל גינה ציבורית נדנדת קן ציפור נגישה עם אבן עיוורים מסביב לפי תקן 1498 .
מתקני קפיץ :

מתקני הקפיצים:
מיוצרים מחומרי - פלסטיק, מפוליאתילין דחוס או פליאתילין מנופח עץ ומתכת.
המתקנים נעים ע"ג קפיץ חזק וגמיש המיובא מחו"ל ומיועד במיוחד למתקנים אלו.
הקפיץ - מאפשר התנדנדות בשתי זוויות או ב 360 מעלות .
על גבי הקפיץ מותקנים הדמויות השונות עם מושבים, ידיות אחיזה ורגליות.
מס' המשתמשים בקפיץ נע בין 1-4 בו זמנית .
קרוסלות :
מיוצר מפלסטיק או ממתכת עם משטח מונע החלקה ומושב מפלסטיק או ממתכת .
הקרוסלה "מונעת" על-ידי סיבוב ההגה/הציר המרכזי לאחד הצדדים ובעזרת מייסבים איכותיים .
מתקני חבלים ואקסטרים :
מתקני חבלים וכבלים:
פארק חבלים הינו חוויה מלהיבה לילדים.
המתקנים משלבים פעילות ספורטיבית לילדים,
תוך שימוש בכוח, גמישות ואתלטיות.

הילדים נרגשים להיכנס לפארק חבלים חדש ומיוחד.
הפארק מושך את הילדים לרוץ ולטפס על המתקנים,
להשתולל ולהוציא אנרגיות חיוביות.

האלמנטים בפארק הינם עמידים ובטוחים לשימוש
ועומדים בסטנדרטים של מכון התקנים הישראלי.

המתקנים פארק עשויים ממתכת או עץ  וחבלים
אשר עמידים בלחצים גבוהים ומשקלים כבדים.
פארק חבלים מורכב בדרך כלל מ:
סולמות חבלים, פירמידות אינדיאניות, רשתות חבלים ,חבלים וכבלי טיפוס,
רשת קורי עכביש, ערסלים, גשרים, מאחזי ידיים  ,מגלשות בצורות ייחודיים .

מתקני חצר לגני ילדים וחטיבות צעירות :
מתקני חצר לגני ילדים הם אטרקציה מיוחדת עבור הילדים.
הצבעים, האתגר והתגברות על הפחד מלהיבה את הילדים לשחק בהם.
מתקני חצר משלבים פעילות גופנית לילדים תוך כדי משחק ושעשוע.
מתקני חצר לגני ילדים מיוצרים בגדלים מותאמים לשימוש ילדים.
המשחק במתקני חצר משלב חוויה והתמודדות חברתית.
גננות בגני ילדים יכולות לבחון את המשחק המשותף של הילדים, עם חבריהם לגן ולאתגר בעיות חברתיות אצל הילדים.
מתקני חצר לגני ילדים מפתחים מוטוריקה, גמישות וחשיבה
איך מתקני חצר תורמים לילדים ולהורים?

בדיקת כישורים:
בניגוד להרבה אמירות בבתי הספר, ילדים בגיל בית ספר לא גדולים מדי בשביל לשחק.
הם משחקים במרץ בכל זמן שניתן להם ההזדמנות.
למעשה, המשחק שלהם יכול לגרום להם לנטילת סיכונים מיותרים.
בניית מתקני חצר בבית הספר יסודיים או גני ילדים נותנת הזדמנות לבדוק את היכולות שלהם עם מגוון רחב של אתגרים שונים כגון: טיפוס, שיווי משקל, גמישות ועוד.
וכל זאת עם שמירה על בטיחות הילדים בזמן המשחק ע"י מתקנים שהותאמו לכך.
פעילות לילדים:

פעילות וכושר לילדים לא צריך להיות רק דרך קורסים ופעילות מבוקרת.
למעשה, הילדים עשויים ליהנות מפעילות בזמן משחק חופשי במתקני חצר בבית הספר או בגן ילדים מאשר במהלך שיעור מובנה בחינוך גופני או פעילות אחרת
גוף הילד והתפתחותו:

כאשר הילדים משחקים במתקני חצר הם נהנים ומתלהבים, יחד עם זאת הם מפעילים את השירירים בכל הגוף.
הפעילות תורמת להתפתחות הילדים מפלג גוף עליון, דרך השכמות, חיזוק הגב, הבטן והיריכיים ועד כפות הרגליים.
ההתפתחות הפיזית של הילדים חשובה להם בגילאים מבוגרים יותר, אבל בכל גיל חשוב להם החוזק, הגמישות והאתלטיות.

לסיכום:
פעילות ספורטיבית ושימור הבריאות והכושר חשובים בכל גיל, ילדים צריכים להיות פעילים גופנית ולשמור על תזונה נכונה.
הם אינם מודעים לזה כמו המבוגרים, אך אנו צריכים לשים לב בשבילם, לבריאות וההתפתחות הגופנית שלהם.`;

export const kindergardenDescription = `מתקני חצר לגני ילדים מתאימים לפארקים, גנים ציבוריים, בתי ספר, חופי ים, מלונות ועוד.
משטחי בטיחות למתקני חצר ניתן להזמין בהתאם לסוג המתקן.
לפי דרישות מכון התקנים הישראלי תקן 1498 למתקני חצר, משטחי בטיחות מאושרים לשימוש הינם משטח גומי, סומסומית גן, חול ים.* חלק מהמתקנים מורשים להתקנה על דשא, אדמה ובטון.

מתקני חצר לגני ילדים הם אטרקציה מיוחדת עבור הילדים.
הצבעים, האתגר והתגברות על הפחד מלהיבה את הילדים לשחק בהם.
מתקני חצר משלבים פעילות גופנית לילדים תוך כדי משחק ושעשוע.מתקני חצר לגני ילדים מיוצרים בגדלים מותאמים לשימוש ילדים.
המשחק במתקני חצר משלב חוויה והתמודדות חברתית.
גננות בגני ילדים יכולות לבחון את המשחק המשותף של הילדים, עם חבריהם לגן ולאתגר בעיות חברתיות אצל הילדים.
מתקני חצר לגני ילדים מוטוריקה, גמישות וחשיבה מרחבית לילדים.
איך מתקני חצר תורמים לילדים ולהורים?

בדיקת כישורים:
בניגוד להרבה אמירות בבתי הספר, ילדים בגיל בית ספר לא גדולים מדי בשביל לשחק.
הם משחקים במרץ בכל זמן שניתן להם ההזדמנות.
למעשה, המשחק שלהם יכול לגרום להם לנטילת סיכונים מיותרים.
בניית מתקני חצר בבית הספר יסודיים או גני ילדים נותנת הזדמנות לבדוק את היכולות שלהם עם מגוון רחב של אתגרים שונים כגון: טיפוס, שיווי משקל, גמישות ועוד.
וכל זאת עם שמירה על בטיחות הילדים בזמן המשחק ע"י מתקנים שהותאמו לכך.
פעילות לילדים:

פעילות וכושר לילדים לא צריך להיות רק דרך קורסים ופעילות מבוקרת.
למעשה, הילדים עשויים ליהנות מפעילות בזמן משחק חופשי במתקני חצר בבית הספר או בגן ילדים מאשר במהלך שיעור מובנה בחינוך גופני או פעילות אחרת
גוף הילד והתפתחותו:

כאשר הילדים משחקים במתקני חצר הם נהנים ומתלהבים, יחד עם זאת הם מפעילים את השירירים בכל הגוף.
הפעילות תורמת להתפתחות הילדים מפלג גוף עליון, דרך השכמות, חיזוק הגב, הבטן והיריכיים ועד כפות הרגליים.
ההתפתחות הפיזית של הילדים חשובה להם בגילאים מבוגרים יותר, אבל בכל גיל חשוב להם החוזק, הגמישות והאתלטיות.

לסיכום:
פעילות ספורטיבית ושימור הבריאות והכושר חשובים בכל גיל, ילדים צריכים להיות פעילים גופנית ולשמור על תזונה נכונה.
הם אינם מודעים לזה כמו המבוגרים, אך אנו צריכים לשים לב בשבילם, לבריאות וההתפתחות הגופנית שלהם.

מתקני שעשועים לילדים ממתכת:
בשנים האחרונות ניתן לראות כי ישנם מקרים רבים של וונדליזם בקרב נערים.
חברת אורן מתקני משחקים מציעה ללקוחותיה, דרך התמודדות עם
הנערים המשחיטים את המתקנים באופן קבוע:
לרכוש מתקני שעשועים ממתכת אשר עמידים יותר מאשר העץ.
מפרט חומרים:
המתקנים מיוצרים מצינורות 4 צול מגולוון,
הצבוע בצבעי אפוקסי תקניים המונעים רעלים ועמידים לאורך זמן.
במתקנים משולבים חבקים יחודיים וברגי נירוסטה,
לחיזוק מושלם של המתקנים והאלמנטים.
המתקנים משלבים אלמנטים נוספים להנאת הילדים המשחקים במתקנים:
אלמנטים ממתכת - גשרים, סולם אופקי, סולמות טיפוס וקירות טיפוס אינדיאני.
אלמטים מפלסטיק - מגלשות, מנהרות זחילה ובועה שקופה.
אלמנטים מפיברגלס - גגונים ומגלשות.
עמידות המתקנים:
מלבד עמידות המתקנים מפני הוונדליזם,
החלקים ממתכת עמידים בפני רטיבות, חום ואש
לאורך שנים ובעלות תחזוקה נמוכה.
אפשרויות הצבה למתקנים:
גני שעשועים, פארקים ציבוריים, מגרשי משחקים וגינות ציבוריות.

מתקני חבלים וכבלים:
פארק חבלים הינו חוויה מלהיבה לילדים.
המתקנים משלבים פעילות ספורטיבית לילדים,
תוך שימוש בכוח, גמישות ואתלטיות.

הילדים נרגשים להיכנס לפארק חבלים חדש ומיוחד.
הפארק מושך את הילדים לרוץ ולטפס על המתקנים,
להשתולל ולהוציא אנרגיות חיוביות.

האלמנטים בפארק הינם עמידים ובטוחים לשימוש
ועומדים בסטנדרטים של מכון התקנים הישראלי.

המתקנים פארק עשויים ממתכת או נירוסטה וחבלים
אשר עמידים בלחצים גבוהים ומשקלים כבדים.
פארק חבלים מורכב בדרך כלל מ:
סולמות חבלים,פירמידות אינדיאניות,רשתות חבלים,חבלים וכבלי טיפוס,
רשת קורי עכביש, ערסלים,גשרים,מאחזי ידיים,מגלשות,מדרגות עץ,
אפשרויות התקנה לפארק:
גני שעשועים, גני ילדים, פארקי כושר וספורט, חופי ים, גנים ציבוריים,
בתי מלון ונופש, גינות פרטיות וציבוריות.

מידע אודות מתקני משחק מעץ:
כחלק מהדרישה ההולכת וגוברת של הרשויות למתקני משחק מעץ,
חברת אורן מתקני משחקים שוקדת בכל עת על מתקני משחק חדשים, אשר יענו על דרישות הלקוחות.
מתקני משחק מיוצרים עבור ילדים, ולכן צריך לגרום לילדים גיוון וחדשנות בתחום.
אנו ממליצים לחלק מלקוחותינו על מתקני משחק מעץ בשל יתרון עמידות בחום.
מתקני משחק ממתכת עשויים התחמם בחודשי הקיץ ולגרום לכוויות בקרב הילדים.
בנוסף המראה של מתקני משחק מעץ הוא אותנטי ונעים לעין ולמגע הילדים.
החומרים:
מתקני משחק מעץ מיוצרים מעץ פיני סוג 5.
העץ עובר תהליך חיטוי ושימור (אימפרגנציה) נגד רטיבות, מזיקים ואש.
המתקנים משלבים אלמנטים נוספים ליצירת חוויה עבור הילדים:
אלמנטים ממתכת - צינור גלישה ומנהרות זחילה.
אלמנטים מפלסטיק - מגלשות, גגונים ומנהרות זחילה.
אלמנטים מפיברגלס - גגונים ומגלשות.
אלמנטים מכבלים וחבלים - קיר טיפוס חבל, רשת חבלים.
פעילות אתגרית לילדים:
טיפוס בסולמות, חבלים, מדרגות, גלישה במגלשות, מנהרות וצינורות.
המתקנים מתאימים לילדים בכל הגילאים בהשגחת מבוגר.
במידה והמתקן מוגבל לגילאים מסוימים, יהיה מצוין על גבי המתקן.
המתקנים בטיחותיים ומיוצרים בהתאם לדרישות מכון התקנים הישראלי.

מתקני משחק מעץ מתאימים לגני שעשועים, מגרשי משחקים, גנים ציבוריים, גני ילדים, כפרי נופש ובתי אירוח.
מתקני משחק מעץ ניתן להתקין על משטח בולם למתקני משחקים מהסוגים הבאים:
סומסומית גן למתקני משחק
חול ים למתקני משחק
משטח גומי למתקני משחק
דשא סינטטי משולב גומי למתקני משחק.
מתקן נדנדות "חובה" בכל גן שעשועים ציבורי.
בקטגוריית הנדנדות תוכלו למצוא:

נדנדות לפעוטות / נדנדות תינוקות עם מושב נדנדה מיוחד לגיל הרך.
נדנדות לילדים / נדנדות לבוגריםעם מושב נדנדה שטוח מגומי.
נדנדות מיוחדות עם מושב נדנדה מרשת חבלים.

מאושרות על ידי מכון התקנים הישראלי תקן 1498.
הנדנדות מתאימות לגני ילדים, פארקים, גנים ציבוריים, בתי ספר ועוד.
הנדנדות מתאימות ועמידות לתנאי חוץ.

שיקולים לבחירת נדנדות:
1. גיל הילדים המשחקים בנדנדות.
2. כמות הילדים המשחקים בנדנדות ( שימוש פרטי או ציבורי ).
3. גודל שטח חצר ההתקנה.
4. מספר מתקנים נוספים המוצבים או מתוכננים בשטח.

מתקני חצר לגני ילדים מתאימים לפארקים, גנים ציבוריים, בתי ספר, חופי ים, מלונות ועוד.
משטחי בטיחות למתקני חצר ניתן להזמין בהתאם לסוג המתקן.
לפי דרישות מכון התקנים הישראלי תקן 1498 למתקני חצר, משטחי בטיחות מאושרים לשימוש הינם משטח גומי, סומסומית גן, חול ים.* חלק מהמתקנים מורשים להתקנה על דשא, אדמה ובטון.

מתקני חצר לגני ילדים הם אטרקציה מיוחדת עבור הילדים.
הצבעים, האתגר והתגברות על הפחד מלהיבה את הילדים לשחק בהם.
מתקני חצר משלבים פעילות גופנית לילדים תוך כדי משחק ושעשוע.מתקני חצר לגני ילדים מיוצרים בגדלים מותאמים לשימוש ילדים.
המשחק במתקני חצר משלב חוויה והתמודדות חברתית.
גננות בגני ילדים יכולות לבחון את המשחק המשותף של הילדים, עם חבריהם לגן ולאתגר בעיות חברתיות אצל הילדים.
מתקני חצר לגני ילדים מוטוריקה, גמישות וחשיבה מרחבית לילדים.
איך מתקני חצר תורמים לילדים ולהורים?

בדיקת כישורים:
בניגוד להרבה אמירות בבתי הספר, ילדים בגיל בית ספר לא גדולים מדי בשביל לשחק.
הם משחקים במרץ בכל זמן שניתן להם ההזדמנות.
למעשה, המשחק שלהם יכול לגרום להם לנטילת סיכונים מיותרים.
בניית מתקני חצר בבית הספר יסודיים או גני ילדים נותנת הזדמנות לבדוק את היכולות שלהם עם מגוון רחב 

של אתגרים שונים כגון: טיפוס, שיווי משקל, גמישות ועוד.
וכל זאת עם שמירה על בטיחות הילדים בזמן המשחק ע"י מתקנים שהותאמו לכך.

פעילות לילדים:
פעילות וכושר לילדים לא צריך להיות רק דרך קורסים ופעילות מבוקרת.
למעשה, הילדים עשויים ליהנות מפעילות בזמן משחק חופשי במתקני חצר בבית הספר או בגן ילדים מאשר במהלך שיעור מובנה בחינוך גופני או פעילות אחרת
גוף הילד והתפתחותו:

כאשר הילדים משחקים במתקני חצר הם נהנים ומתלהבים, יחד עם זאת הם מפעילים את השירירים בכל הגוף.
הפעילות תורמת להתפתחות הילדים מפלג גוף עליון, דרך השכמות, חיזוק הגב, הבטן והיריכיים ועד כפות הרגליים.
ההתפתחות הפיזית של הילדים חשובה להם בגילאים מבוגרים יותר, אבל בכל גיל חשוב להם החוזק, הגמישות והאתלטיות.

לסיכום:
פעילות ספורטיבית ושימור הבריאות והכושר חשובים בכל גיל, ילדים צריכים להיות פעילים גופנית ולשמור על תזונה נכונה.
הם אינם מודעים לזה כמו המבוגרים, אך אנו צריכים לשים לב בשבילם, לבריאות וההתפתחות הגופנית שלהם.
 מתקני ההגאים: הינם נייחים וניתנים לקיבוע לכל סוג קרקע.

מתקנים אלו מאופיינים: במס' רב של משתתפים וחלקם ניתנים להתקנה על דשא או אדמה ללא משטח בולם/כיסוי שטח.

כיסוי שטח: (סומסום/סומסומית גן ,חול ים, משטח גומי).

אפשרות זאת ניתנת בשל גובהם הנמוך מגובה 60 ס"מ.`;

export const shadesDescription = `מפרט טכני : הצללה מסוג מפרשים /נפתח נסגר /ממבראנה
חברת פארק דיזיין בע"מ משתמשת באריג מחוזק של חברת שהד ווונדרפלסט
הנודעת בזכות אריגה יצירתית.האריג אינו סופג לחות ועמיד לאורך שנים, הן בצבעו והן בחוזקו.
חומר גלם: עשוי פוליאתילן בעל דחיסות גבוהה עם מסנני .V.U
נתונים טכניים לאריג:
חומר גלם:
עשוי פוליאתילן בעל דחיסות גבוהה ( HDPE ( לא ממוחזר, המעניק לאריג חוזק ויציבות לאורך
שנים. האריג בעל אלסטיות ויציבות, גם לאחר מתיחה או רוח יחזור למצבו הקודם.
מבנה האריג: אריגה ייחודית במונופילמנט – צורת אריגה זו מונעת תהליך פרימת האריג גם במקרה של
קרע.
חוזק קריעה: לפי תקן 2261/96 ASTMD שתי 022-N , ערב -198. N
ביקוע: 3.7 KN קילו ניוטון (לפי תקן ASTM 7373 או תקן ישראלי מקביל).
משקל החומר: מינימום 702 גרם למ"ר.
אורך חיים:
לאריג עם תקן אש- מינימום חשוף לשמש במשך 3 שנים מפני התבלות מקרינת .V.U
לאריג ללא תקן אש- מינימום חשוף לשמש במשך 02 שנים מפני התבלות מקרינת V.U
מינימום טמפרטורה: מינוס 02 מעלות צלזיוס.
מקסימום טמפרטורה: 72 מעלות צלזיוס.
עובי: מינימום 0.7 מילימטר
הצללה: מינימום 02% – 07% )לפי תקן ישראלי 347 (.
מסנני V.U : מינימום 00% – 07% )לפי תקן ישראלי 347 (.
אחוז מונופילמנט: מינימום 22% .
נתונים טכניים נוספים:
מפרט אביזרים וכבלים:
כבלים: 6 או 7 מ"מ מגולוונים לפי תקן ישראלי 262 .
מפרט מתכת:
מתכת: עמודים מגולוונים .
עמודים4: " " -6 " -7 (צול)- מתכת ו לפי מפרט קונסטרוקטור.
•עמודים מגולוונים.
•אביזרי מתכת מגולוונת.
•כבלים מגולוונים לפי ת"י 262 .

מפרט צביעה:
צביעת העמודים מתבצעת בצבע פוליאורטיני דו רכיבי רטוב, מאושר ע"י מכון התקנים לשימוש במוסדות
חינוך סוג צבע : אוניקריל ללא עופרת של נירלט.
מפרט תפירה:
התפירה: תהיה במכונת דו מחט עם חוט מונופילמנט, כאשר חוט התפירה עשוי מאותו החומר .`;


