import { useEffect, useState } from "react";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import { Gallery, Item } from "react-photoswipe-gallery";
import "./index.scss";
import { useUser } from "../../context/UserContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholderSrc from "../../images/logo.png";

function OurProjects() {
  const [images, setImages] = useState([]);
  const { translate } = useUser();
  const storage = getStorage();
  const options = {
    wheelToZoom: true,
  };
  useEffect(() => {
    if (storage) {
      const load = () => {
        const listRef = ref(storage, "our_projects/");
        listAll(listRef)
          .then((res) => {
            res.items.forEach(async (itemRef) => {
              const url = await getDownloadURL(itemRef);
              setImages((prevState) => [...prevState, url]);
            });
          })
          .catch(() => {
            alert("error happened please contant support!");
          });
      };
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="ourProjectsPage container p-4">
      <h1 className="fw-bold text-center mb-5">{translate("park_projects")}</h1>

      <Gallery options={options}>
        <div className="d-flex gap-3 gap-md-4 flex-wrap">
          {images?.map((img) => {
            return (
              <Item
                original={img}
                thumbnail={img}
                width="1024"
                height="768"
                className="proejct-item"
              >
                {({ ref, open }) => (
                  <div className="img-wrapper">
                    <LazyLoadImage
                      ref={ref}
                      className="img-fluid rounded-4"
                      src={img}
                      onClick={open}
                      placeholderSrc={placeholderSrc}
                      effect="blur"
                      alt="Project"
                    />
                  </div>
                )}
              </Item>
            );
          })}
        </div>
      </Gallery>
    </div>
  );
}

export default OurProjects;
