import React from "react";
import videoSrc from "../../images/home/park-d.mp4";
import banner1Src from "../../images/home/banner-1.jpg";
import banner3Src from "../../images/home/banner-3.webp";

import "./index.scss";
import { Carousel } from "react-bootstrap";
import { TypeAnimation } from "react-type-animation";

import { FaCheck } from "react-icons/fa";
import { useProducts } from "./../../context/ProductsContext";
import { isEmpty, snakeCase } from "lodash";
import Categories from "./../../components/Categories/index";
import { useUser } from "../../context/UserContext";
import { hebrewLang } from "../../components/Header";

function Home() {
  const { textCategories } = useProducts();
  const { translate, lang } = useUser();
  return (
    <div className="homePage p-4 pb-0">
      <div className="position-relative">
        <Carousel pause={false} variant="dark" className="mb-4">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((image) => (
            <Carousel.Item key={image}>
              <img
                className="d-block w-100 carouselImage"
                src={`${process.env.PUBLIC_URL}/image${image}.jpg`}
                alt={`${image}-slide`}
              />
            </Carousel.Item>
          ))}
        </Carousel>
        <Carousel.Caption>
          <div className="fixedTitle">{translate("your_partner")}</div>
          {!isEmpty(textCategories) ? (
            <TypeAnimation
              sequence={textCategories?.flatMap((x) => [
                `${translate(snakeCase(x))}`,
                5000,
              ])}
              wrapper="div"
              cursor={true}
              repeat={Infinity}
              style={{ textTransform: "capitalize", color: "#7fbc4e" }}
              key={`type-animation-${lang}`}
            />
          ) : null}
        </Carousel.Caption>
      </div>
      <div className="videoWrapper mb-4">
        <video width="400" autoPlay loop muted controls className="video">
          <source src={videoSrc} type="video/mp4" />
          Your browser does not support HTML video.
        </video>
      </div>
      <div className="vva">
        <div className="banner1 banner container d-flex align-items-center fs-5">
          <div className="textContent">
            {lang === hebrewLang ? (
              <div className="rightDirection">
                {translate("home_content_1")}
              </div>
            ) : (
              <>
                <b>Park Design</b> offers a full complement of park and
                playground products including wood equipment, themed equipment,
                splash park equipment, seating, outdoor fitness equipment.
                <div className="d-flex flex-column mt-2 gap-1">
                  <span>
                    <FaCheck /> Certified inclusive design specialists and
                    educators
                  </span>
                  <span>
                    <FaCheck /> Supervised installation options
                  </span>
                  <span>
                    <FaCheck /> 20+ years of experience designing and building
                    play
                  </span>
                  spaces
                </div>
              </>
            )}
          </div>
          <div className="position-relative">
            <img src={banner3Src} alt="Banner image1" />
            <div className="bg-img" />
          </div>
        </div>
      </div>

      <div className="banner2 banner container d-flex align-items-center fs-5">
        <div className="position-relative">
          <img src={banner1Src} alt="Banner image2" />
          <div className="bg-img " />
        </div>
        <div className="textContent">
          {lang === hebrewLang ? (
            <div className="rightDirection">{translate("home_content_2")}</div>
          ) : (
            <>
              <h1 className="fw-bolder mb-4 text-dark">
                Planning Your Play Space
              </h1>
              Planning and managing a play space project is a big deal. It
              requires a lot of time, energy, and detail—having a trusted
              partner on your side can make all the difference.
              <div className="fw-bold my-3">
                You don't need another cookie-cutter design. You need a team
                that will take the time to understand you and your site's unique
                needs and who will go the extra mile to ensure you're 100%
                satisfied with the end result.
              </div>
              When you choose Park Design, you'll be taking the stress and
              unknowns out of the equation. You can sit back and relax, knowing
              you have an entire team backing you up.
            </>
          )}
        </div>
      </div>

      <div className="homeCat">
        <div className="container">
          <Categories />
        </div>
      </div>
    </div>
  );
}

export default Home;
