import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD8rG2nyV1QKw35M3nMm7w-eHwcKXwRDgk",
  authDomain: "design-park.firebaseapp.com",
  projectId: "design-park",
  storageBucket: "design-park.appspot.com",
  messagingSenderId: "232354225362",
  appId: "1:232354225362:web:d537445f625a393477610b",
  measurementId: "G-4N358JL01C",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const firestore = getFirestore(app);
export const storage = getStorage(app);
export default firestore;
