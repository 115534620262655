import React from "react";
import { ReactComponent as Loader } from "../../images/loader.svg";
import "./index.scss";

function PageLoader() {
  return (
    <div className="pageLoading">
      <Loader />
    </div>
  );
}

export default PageLoader;
