import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useProducts } from "./../../context/ProductsContext";
import "./index.scss";
import { isEmpty } from "lodash";
import { getDoc, doc } from "firebase/firestore/lite";
import firestore from "../../firebase-setup/firebase";

import ageRangeSrc from "../../images/product/age-group.png";
import usesNumberSrc from "../../images/product/crowd.png";
import dropHeightSrc from "../../images/product/height.png";
import securitySrc from "../../images/product/security.png";
import { Gallery, Item } from "react-photoswipe-gallery";
import { useUser } from "./../../context/UserContext";
import classNames from "classnames";

const ProductInfo = ({ title, src, value }) =>
  !isEmpty(value) ? (
    <div className="product-info d-flex flex-column text-center fw-bold gap-2 rounded-1 p-2">
      {title}
      <img src={src} alt={title} className="mx-auto" />
      {value}
    </div>
  ) : null;

function Product() {
  const { categoryUuid, productUuid } = useParams();
  const { products, setProducts } = useProducts();
  const { setIsLoading } = useUser();

  const product = products[categoryUuid ?? ""]?.find(
    (item) => item.id === productUuid
  );

  const options = {
    wheelToZoom: true,
  };

  useEffect(() => {
    if (categoryUuid && productUuid && isEmpty(products)) {
      const load = async () => {
        setIsLoading(true);

        const ref = doc(firestore, "Products", productUuid);
        const result = await getDoc(ref);

        if (result?.exists()) {
          setProducts({
            [categoryUuid]: [{ id: result.id, ...result.data() }],
          });
        }
        setTimeout(() => setIsLoading(false), 500);
      };

      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryUuid, productUuid, products]);

  return (
    <div className="productPage container p-4">
      {product ? (
        <>
          <h1 className="text-capitalize mb-5 text-center">
            {product?.name ? (
              <span className="name fw-normal">{product.name} - </span>
            ) : null}
            {product?.id ? (
              <span className="id text-uppercase fw-bolder">{product.id}</span>
            ) : null}
          </h1>
          {!isEmpty(product?.img) ? (
            <Gallery options={options}>
              <div
                className={classNames("imagesContainer d-flex gap-2 gap-md-4", {
                  "justify-content-center": product.img?.length === 1,
                })}
              >
                <div className="product-section-1">
                  <Item
                    original={product.img[0]}
                    thumbnail={product.img[0]}
                    width="1287"
                    height="873"
                    id={0}
                  >
                    {({ ref, open }) => (
                      <img
                        className="img-fluid rounded-4 product-img"
                        src={product.img[0]}
                        alt={product?.name ?? product?.id ?? "Product"}
                        ref={ref}
                        onClick={open}
                      />
                    )}
                  </Item>
                </div>
                {product?.img[1] ? (
                  <div className="product-section-2">
                    <Item
                      original={product.img[1]}
                      thumbnail={product.img[1]}
                      width="1287"
                      height="873"
                      id={1}
                    >
                      {({ ref, open }) => (
                        <img
                          className="img-fluid rounded-4 mb-2 mb-md-3 product-img"
                          src={product?.img[1]}
                          alt={product?.name ?? product?.id ?? "Product"}
                          ref={ref}
                          onClick={open}
                        />
                      )}
                    </Item>
                    {product?.img[2] ? (
                      <Item
                        original={product.img[2]}
                        thumbnail={product.img[2]}
                        width="1287"
                        height="873"
                        id={2}
                      >
                        {({ ref, open }) => (
                          <img
                            className="img-fluid rounded-4 mb-3 product-img"
                            src={product?.img[2]}
                            alt={product?.name ?? product?.id ?? "Product"}
                            ref={ref}
                            onClick={open}
                          />
                        )}
                      </Item>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </Gallery>
          ) : null}

          {product.age_range ||
          product.drop_height ||
          product.number_users ||
          product.security_area ? (
            <div className="product-info-wrapper rounded-4 p-2 bg-white d-flex gap-3 justify-content-around px-3 mt-3 mt-md-5">
              <ProductInfo
                title="Age range"
                src={ageRangeSrc}
                value={product.age_range}
              />
              <ProductInfo
                title="Number of users"
                src={usesNumberSrc}
                value={product.number_users}
              />
              <ProductInfo
                title="Drop height"
                src={dropHeightSrc}
                value={product.drop_height ? `${product.drop_height}m` : null}
              />
              <ProductInfo
                title="Security area"
                src={securitySrc}
                value={product.security_area}
              />
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default Product;
