import React, { useEffect, useState } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

import "./index.scss";
import { useUser } from "../../context/UserContext";

function Certificates() {
  const [url, setUrl] = useState("");
  const { setIsLoading, translate } = useUser();

  const storage = getStorage();
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const result = await getDownloadURL(
        ref(storage, "certificates/park-design-certificates.pdf")
      );
      setUrl(result);
      setTimeout(() => setIsLoading(false), 300);
    };
    if (storage) load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storage]);
  return (
    <div className="certificatesPage vh-100 p-4">
      <div className="position-relative mb-5">
        <h1 className="fw-bold text-center">
          {translate("park_certificates")}
        </h1>
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className="position-absolute end-0"
        >
          {translate("open_certificates")}
        </a>
      </div>

      <iframe
        src={url}
        title="Park design Certificates"
        height="100%"
        width="100%"
        type="application/pdf"
      />
    </div>
  );
}

export default Certificates;
