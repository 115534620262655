import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { collection, query, where, getDocs } from "firebase/firestore/lite";
import firestore from "../../firebase-setup/firebase";
import { head, isEmpty, snakeCase } from "lodash";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./index.scss";
import { useProducts } from "./../../context/ProductsContext";
import { useUser } from "./../../context/UserContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import placeholderSrc from "../../images/logo.png";
import {
  // kindergardenDescription,
  shadesDescription,
} from "../../meta/site-description";
import { hebrewLang } from "../../components/Header";
import ShadesText from "../../meta/ShadesText";
// import KinderText from './../../meta/KinderText';

function Category() {
  const { categoryUuid } = useParams();
  const { products, setProducts } = useProducts();
  const { setIsLoading, loadedCatPages, setLoadedCatPages, translate, lang } =
    useUser();

  const categoryProducts = products[categoryUuid ?? ""];

  const isShades = categoryUuid === "shades";
  // const isKindergarten = categoryUuid === "kindergarten_playground";

  useEffect(() => {
    if (categoryUuid && !loadedCatPages[categoryUuid]) {
      const load = async () => {
        setIsLoading(true);
        const ref = collection(firestore, "Products");
        const prductsQuery = query(
          ref,
          where("parent", "==", categoryUuid?.replaceAll("_", " "))
        );

        const result = await getDocs(prductsQuery);
        setProducts({
          [categoryUuid]: result?.docs?.map((x) => ({ id: x.id, ...x.data() })),
        });
        setLoadedCatPages({ [categoryUuid]: true });
        setTimeout(() => setIsLoading(false), 500);
      };

      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryUuid]);

  return (
    <>
      <Helmet>
        <title>{translate(snakeCase(categoryUuid))}</title>
        {isShades ? (
          <meta name="description" content={shadesDescription} />
        ) : null}
        {/* {isKindergarten ? (
          <meta name="description" content={kindergardenDescription} />
        ) : null} */}
      </Helmet>

      <div className="categoryPage container p-4">
        <h1 className="text-capitalize text-center fw-bold mb-3 mb-md-5">
          {translate(snakeCase(categoryUuid))}
        </h1>
        <div className="categoryProducts d-flex flex-wrap gap-4">
          {!isEmpty(categoryProducts) ? (
            categoryProducts?.map((product) => (
              <Link
                to={`product/${product?.id}`}
                key={`cat-product${product?.id}`}
              >
                <div className="categoryProduct h-100 d-flex flex-column p-2 rounded-3 cursor-pointer">
                  {!isEmpty(product?.img) ? (
                    <LazyLoadImage
                      className="img-fluid rounded-3 mb-2"
                      src={head(product?.img)}
                      placeholderSrc={placeholderSrc}
                      wrapperClassName="flex-grow-1"
                      effect="blur"
                      alt={product?.name ?? product?.id ?? "Product"}
                    />
                  ) : null}
                  {product?.name ? (
                    <div className="name">{product.name}</div>
                  ) : null}
                  {product?.id ? <div className="id">{product.id}</div> : null}
                </div>
              </Link>
            ))
          ) : (
            <div className="w-100 align-center fs-4 mt-5 fw-semibold bg-white rounded p-2">
              No products
            </div>
          )}
        </div>
        {isShades && lang === hebrewLang ? (
          <div className="mt-5 d-rtl">
            <ShadesText />
          </div>
        ) : null}

        {/* {isKindergarten && lang === hebrewLang ? (
          <div className="mt-5 d-rtl">
            <KinderText />
          </div>
        ) : null} */}
      </div>
    </>
  );
}

export default Category;
