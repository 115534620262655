import React from "react";
import Logo from "../../images/logo.png";
import { BsInstagram } from "react-icons/bs";
import { GrFacebookOption } from "react-icons/gr";
import { FaTiktok, FaWhatsapp } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import { MdOutlineLocationOn } from "react-icons/md";
import { BiPhoneCall } from "react-icons/bi";
import { MdOutlineMail } from "react-icons/md";

import "./index.scss";
import { useUser } from "../../context/UserContext";

function Footer() {
  const { translate } = useUser();
  return (
    <footer className="footer p-3 pt-4 ">
      <div className="d-flex flex-column align-items-center justify-content-center footer-wrapper container">
        <div className="footer-logo">
          <img src={Logo} alt="logo" />
        </div>
        <div className="w-100 d-flex justify-content-center justify-content-md-between align-items-center mt-4">
          <div className="followUs d-none d-md-block">
            {translate("follow_us")}
          </div>
          <div className="d-flex justify-content-center  gap-4">
            <a
              rel="noreferrer"
              href="https://www.instagram.com/"
              target="_blank"
              className="social-icon"
            >
              <BsInstagram />
            </a>
            <a
              rel="noreferrer"
              href="https://www.facebook.com/"
              target="_blank"
              className="social-icon"
            >
              <GrFacebookOption />
            </a>
            <a
              rel="noreferrer"
              href="https://www.tiktok.com/"
              target="_blank"
              className="social-icon"
            >
              <FaTiktok />
            </a>

            <a
              rel="noreferrer"
              href="https://www.youtube.com/"
              target="_blank"
              className="social-icon"
            >
              <BsYoutube />
            </a>

            <a
              rel="noreferrer"
              href="https://wa.link/zeuhuw"
              target="_blank"
              className="social-icon"
            >
              <FaWhatsapp />
            </a>
          </div>
        </div>
        <div className="w-100 d-flex justify-content-between flex-column flex-md-row align-items-center mt-4 gap-3 contactInfo">
          <div>
            <MdOutlineLocationOn />
            <a
              href="https://goo.gl/maps/hfo7mmAxZ87dggpcA"
              target={"_blank"}
              rel="noreferrer"
            >
              Bartaa, basma, zip code: 3002300, Israel
            </a>
          </div>
          <div>
            <BiPhoneCall />
            <a role="button" href="tel:00972-46050155">
              {" "}
              00972-46050155
            </a>
          </div>
          <div>
            <MdOutlineMail />
            <a href="mailto:info@play-parkdesign.com">info@play-parkdesign.com</a>
          </div>
        </div>
        <div className="copyRight mt-3">
          {translate("copy_right")} {new Date().getFullYear()} .
        </div>
      </div>
    </footer>
  );
}

export default Footer;
