import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import { ProductsProvider } from "./context/ProductsContext";
import { UserProvider } from "./context/UserContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export function AllProviders({ children }) {
  return (
    <QueryClientProvider client={queryClient}>
      <UserProvider>
        <ProductsProvider>
          <BrowserRouter>{children}</BrowserRouter>
        </ProductsProvider>
      </UserProvider>
    </QueryClientProvider>
  );
}
