import React, { useEffect, useState } from "react";
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";

import "./index.scss";
import { useUser } from "../../context/UserContext";

function AboutUs() {
  const { setIsLoading } = useUser();
  const { translate } = useUser();
  const [files, setFiles] = useState([]);

  const storage = getStorage();
  useEffect(() => {
    if (storage) {
      const load = () => {
        setIsLoading(true);
        const listRef = ref(storage, "about_us/");
        listAll(listRef)
          .then((res) => {
            res.items.forEach(async (itemRef) => {
              const url = await getDownloadURL(itemRef);
              setFiles((prevState) => [...prevState, url]);
            });
            setTimeout(() => setIsLoading(false), 300);
          })
          .catch(() => {
            alert("error happened please contant support!");
          });
      };
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="aboutUsPage p-4">
      <h1 className="fw-bold text-center mb-5">{translate("about_us")}</h1>
      <div className="files d-flex">
        {files?.map((file) => (
          <div>
            <iframe
              src={file}
              title="About us"
              height="100%"
              width="100%"
              type="application/pdf"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default AboutUs;
